import { DisplayMode, FileValidationRules } from '@amzn/limestone-experiment-portal-types';
import { ExperimentAttributeProps } from '../../ExperimentAttribute';
import { FileField, FileFieldConfig } from '../../fields/FileField';

/**
 * File defining a region definition that includes a list of boundaries and their zip or postal codes.
 */
export class RegionDefinitionFile extends FileField<ExperimentAttributeProps> {
    protected displayConfig: FileFieldConfig;
    protected validationRules: FileValidationRules;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        this.validationRules = {
            required: true,
            allowedOptions: ['text/csv'],
            maxSizeInBytes: 4000000000,
            duplicateCheck: false,
            emptyCheck: true,
        };

        this.displayConfig = {
            label: 'Region Definition File',
            editable: false,
            touched: false,
            value: [],
            i18nStrings: {
                uploadButtonText: (e: any) => e ? 'Upload Region Definition File' : '',
                dropzoneText: (e: any)=> e ? 'Drop file' : '',
                removeFileAriaLabel: (e: any) => `Remove file ${e + 1}`,
                limitShowFewer: 'Show fewer files',
                limitShowMore: 'Show more files',
                errorIconAriaLabel: 'Error'
            },
            showFileSize: true,
            showFileThumbnail: true,
            tokenLimit: 1,
            multiple: false,
            constraintText: 'Please upload the region definition files in csv. See https://w.amazon.com/bin/view/Limestone/RXRegionManagementService/RegionDefinitionFile/ for detailed format.',
            onChange: (event: any) => this.onChangeEvent(event, 'Region Definition File')
        };

        this.summaryDisplay = null;

        this.state = {
            displayValue: '',
            displayMode: props.displayMode ? props.displayMode : DisplayMode.CREATE,
            editInProgress: false,
            validity: false
        };
    }

    componentDidMount = async() => {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }

        this.forceUpdate();
    }

    setValue = async(newValue: ReadonlyArray<File>) => {
        let files: ReadonlyArray<File> = [];
        if (newValue && newValue.length) {
            files = newValue;
        }

        this.validateAndSetFileValue(files);
    }

    setValueFromPayload = async(newContent: File) => {
        this.setValue([newContent]);
    }

    getPayloadValue = () => this.displayConfig.value[0];
}
