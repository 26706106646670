import React, { FunctionComponent } from 'react';
import { Box, ColumnLayout, Container } from '@amzn/awsui-components-react-v3';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { PageSection } from '../../layout/PageSection';
import InfoIcon from '@material-ui/icons/Info';
import UnsubscribeIcon from '@material-ui/icons/Unsubscribe';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { ExternalLinks } from '@amzn/limestone-experiment-portal-types';

const useStyles = makeStyles((theme) => ({
    header: {
        textAlign: 'center',
        padding: '20x',
        margin: '15px 5px 0 0'
    },
    container: {
        marginBottom: '30px',
        height: '140px'
    },
    box: {
        alignContent: 'center',
        textAlign: 'center',
        height: '120px',
        padding: '40x'
    },
    button: {
        marginTop: '10px'
    },
    pageSection: {
        height: '140px'
    }
}));

export const QuickStartSection: FunctionComponent = () => {
    const classes = useStyles();

    return (
        <Container>
            <PageSection testId={'quickstart-section'} className={classes.pageSection}>
                <ColumnLayout columns={4} variant='text-grid'>
                    <div className={classes.box}>
                        <Box variant='h3'>Learn about ReSES</Box>
                        <a href={ExternalLinks.WIKI.href}>
                            <Button
                                variant='contained'
                                size='medium'
                                color='primary'
                                className={classes.button}
                                endIcon={<InfoIcon />}>
                                <Box variant='h5' color={'inherit'}>Wiki</Box>
                            </Button>
                        </a>
                    </div>
                    <div className={classes.box}>
                        <Box variant='h3'>Onboarding Guide</Box>
                        <a href={ExternalLinks.EXPERIMENT_INTAKE.href}>
                            <Button
                                variant='contained'
                                size='medium'
                                color='primary'
                                href='https://w.amazon.com/bin/view/Limestone/HowToGuide'
                                className={classes.button}
                                endIcon={<InfoIcon />}
                            >
                                <Box variant='h5' color={'inherit'}>Onboarding Guide</Box>
                            </Button>
                        </a>
                    </div>
                    <div className={classes.box}>
                        <Box variant='h3'>Submit a feature request</Box>
                        <a href={ExternalLinks.FEEDBACK_INTAKE.href}>
                            <Button
                                variant='contained'
                                size='medium'
                                color='primary'
                                className={classes.button}
                                endIcon={<FeedbackIcon />}>
                                <Box variant='h5' color={'inherit'}>Feedback</Box>
                            </Button>
                        </a>
                    </div>
                    <div className={classes.box}>
                        <Box variant='h3'>Subscribe to our mailing list!</Box>
                        <a href={ExternalLinks.MAILING_LIST.href}>
                            <Button
                                variant='contained'
                                size='medium'
                                color='primary'
                                className={classes.button}
                                endIcon={<UnsubscribeIcon />}>
                                <Box variant='h5' color={'inherit'}>Subscribe</Box>
                            </Button>
                        </a>
                    </div>
                </ColumnLayout>
            </PageSection>
        </Container>
    );
};
