import { SelectProps } from '@amzn/awsui-components-react-v3';
import * as LambdaModel from '@amzn/limestone-experiment-portal-types';
import { DisplayMode, MetadataAttribute } from '@amzn/limestone-experiment-portal-types';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { MultiSelectField, MultiSelectFieldConfig } from '../fields/MultiSelectField';
import { AttributeLabels } from '@amzn/limestone-experiment-portal-types';

export class SecondaryBusinessGroups extends MultiSelectField<ExperimentAttributeProps> {
    protected displayConfig: MultiSelectFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        this.displayConfig = {
            label: AttributeLabels.SECONDARY_BUSINESS_GROUPS,
            editable: false,
            touched: false,
            placeholder: 'Select from the drop-down',
            filteringType: 'auto',
            onChange: (event) => this.onChangeEvent(event as CustomEvent, MetadataAttribute.SECONDARY_BUSINESS_GROUPS),
            selectedOptions: [],
        };

        this.state = {
            displayValue: '',
            displayMode: props.displayMode ? props.displayMode : DisplayMode.CREATE,
            validity: true
        };
    }

    componentDidMount = async() => {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
    }

    getPayloadValue = (): LambdaModel.BusinessGroupDto[] => {
        const businessGroups: LambdaModel.BusinessGroupDto[] = [];
        this.getSelectedOptions().forEach((selectedOption) => {
            businessGroups.push({
                businessGroup: selectedOption.label!,
                primary: false
            });
        });

        return businessGroups;
    };

    setValueFromPayload = async(businessGroups: LambdaModel.BusinessGroupDto[]) => {
        const selectedOptions: SelectProps.Option[] = [];
        businessGroups.forEach((businessGroupDto) => {
            selectedOptions.push({
                value: businessGroupDto.businessGroup,
                label: businessGroupDto.businessGroup
            });
        });

        this.setValue(selectedOptions);
    }
}
