import { RegionDefinitionType } from '@amzn/limestone-experiment-portal-types';
import { TableProps } from '@amzn/awsui-components-react-v3';

export const COLUMN_DEFINITIONS: Array<TableProps.ColumnDefinition<RegionDefinitionType>> = [
    {
        id: 'Name',
        header: 'Name',
        cell: (item) => item.name,
        width: 200,
        sortingField: 'name'
    },
];

export const COLUMN_OPTIONS = [
    { id: 'Name', label: 'Name', editable: true, visible: true, },
];

export const PAGE_SIZE_OPTIONS = [{ value: 10, label: '10 region definitions' }];
