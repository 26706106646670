import React, { FunctionComponent } from 'react';
import { ColumnLayout, ExpandableSection, Header } from '@amzn/awsui-components-react-v3';
import { DisplayMode } from '@amzn/limestone-experiment-portal-types';
import {
    AdditionalCustomMetricsField,
    Description,
    Discriminator,
    EndDate,
    Marketplace,
    Observers,
    PrimaryBusinessGroup,
    PrimaryOwner,
    ProductFamily,
    RablRegionId,
    SecondaryBusinessGroups,
    SecondaryOwners,
    StartDate,
    Tags,
    Title,
    RegionDefinitionType,
} from '../../form/attributes';
import { LifecycleType, Realm } from '@amzn/limestone-experiment-portal-types';
import { IProps,
    LimestoneExperimentMetadata,
    ActionType,
    PermissionsMap,
    UserAccessLevel } from '@amzn/limestone-experiment-portal-types';
import { isActionAuthorizedForUser } from '../../utils/auth-utils';
import { EXPERIMENT_STATUSES_ALLOWED_FOR_EDITING_START_DATE, ExperimentStatusType } from '../../enums/ExperimentStatus';
import { ExperimentType } from '../../form/attributes/ExperimentType';

export interface ExperimentDefinitionSectionProps extends IProps {
    realm: Realm;
    experimentId: string;
    metadata: LimestoneExperimentMetadata;
    userAccessLevels: Set<UserAccessLevel>;
    pagePermissionsMap: PermissionsMap;
    experimentStatus?: ExperimentStatusType;
}

export const ExperimentDefinitionSection: FunctionComponent<ExperimentDefinitionSectionProps> = ({
    realm,
    testId,
    metadata,
    experimentId,
    userAccessLevels,
    pagePermissionsMap,
    experimentStatus
}) => {
    const isWriteActionEnabledForUser = isActionAuthorizedForUser(userAccessLevels, ActionType.WRITE, pagePermissionsMap);

    return (
        <div style={{ padding: 20 }}>
            <ExpandableSection headerText={<Header variant="h2">Experiment Definition</Header>} variant='container' defaultExpanded={true} data-testid={testId}>
                <ColumnLayout columns={4} variant='text-grid'>
                    <Marketplace
                        data-testid='marketplace-dropdown'
                        realm={realm}
                        displayMode={DisplayMode.VIEW}
                        initialValue={metadata.marketplace.payloadValue}
                    />
                    <Title
                        data-testid='title-input'
                        displayMode={DisplayMode.VIEW}
                        experimentId={experimentId}
                        realm={realm}
                        isAuthorizedToEdit={isWriteActionEnabledForUser}
                        initialValue={metadata.title.payloadValue}
                    />
                    <Description
                        data-testid='description-input'
                        displayMode={DisplayMode.VIEW}
                        experimentId={experimentId}
                        realm={realm}
                        initialValue={metadata.description.payloadValue}
                        isAuthorizedToEdit={isWriteActionEnabledForUser}
                    />
                    <ProductFamily
                        data-testid='product-family-dropdown'
                        displayMode={DisplayMode.VIEW}
                        initialValue={metadata.productFamily.payloadValue}
                    />
                    <PrimaryBusinessGroup
                        data-testid='primary-business-group-dropdown'
                        displayMode={DisplayMode.VIEW}
                        initialValue={metadata.primaryBusinessGroup.payloadValue}
                    />
                    <SecondaryBusinessGroups
                        data-testid='secondary-business-groups-dropdown'
                        displayMode={DisplayMode.VIEW}
                        initialValue={metadata.secondaryBusinessGroups.payloadValue}
                    />
                    <PrimaryOwner
                        data-testid='primary-owner-input'
                        displayMode={DisplayMode.VIEW}
                        initialValue={metadata.primaryOwner.payloadValue}
                    />
                    <SecondaryOwners
                        data-testid='secondary-owners-input'
                        displayMode={DisplayMode.VIEW}
                        experimentId={experimentId}
                        realm={realm}
                        initialValue={metadata.secondaryOwners.payloadValue}
                        isAuthorizedToEdit={isWriteActionEnabledForUser}
                    />
                    <Observers
                        data-testid='observers-input'
                        displayMode={DisplayMode.VIEW}
                        experimentId={experimentId}
                        realm={realm}
                        initialValue={metadata.observers.payloadValue}
                        isAuthorizedToEdit={isWriteActionEnabledForUser}
                    />
                    <Tags
                        data-testid='tags-input'
                        displayMode={DisplayMode.VIEW}
                        experimentId={experimentId}
                        realm={realm}
                        initialValue={metadata.tags.payloadValue}
                        isAuthorizedToEdit={isWriteActionEnabledForUser}
                    />
                    {metadata.experimentType.payloadValue !== LifecycleType.RESULT_ANALYSIS_ONLY && <RablRegionId
                        data-testid='rabl-region-id-input'
                        displayMode={DisplayMode.VIEW}
                        initialValue={metadata.rablRegionId.payloadValue}
                    />}
                    <RegionDefinitionType
                        data-testid='region-definition-type-dropdown'
                        displayMode={DisplayMode.VIEW}
                        initialValue={metadata.regionDefinitionType.payloadValue}
                    />
                    <Discriminator
                        data-testid='discriminator-input'
                        displayMode={DisplayMode.VIEW}
                        initialValue={metadata.discriminator.payloadValue}
                    />
                    <StartDate
                        data-testid='start-date-input'
                        displayMode={DisplayMode.VIEW}
                        experimentId={experimentId}
                        realm={realm}
                        initialValue={metadata.startDate.payloadValue}
                        endDate={metadata.endDate.payloadValue}
                        isAuthorizedToEdit={userAccessLevels.has(UserAccessLevel.ADMIN)}
                        editable={EXPERIMENT_STATUSES_ALLOWED_FOR_EDITING_START_DATE.includes(experimentStatus!)}
                    />
                    <EndDate
                        data-testid='end-date-input'
                        displayMode={DisplayMode.VIEW}
                        experimentId={experimentId}
                        realm={realm}
                        initialValue={metadata.endDate.payloadValue}
                        startDate={metadata.startDate.payloadValue}
                        isAuthorizedToEdit={userAccessLevels.has(UserAccessLevel.ADMIN)}
                        editable={true}
                    />
                    <AdditionalCustomMetricsField
                        data-testid='additional-custom-metrics-dropdown'
                        displayMode={DisplayMode.VIEW}
                        initialValue={metadata.customMetrics.payloadValue}
                        editable={false}
                    />
                    <ExperimentType
                        data-testid='experiment-type-input'
                        displayMode={DisplayMode.VIEW}
                        label='Experiment Type'
                        experimentId={experimentId}
                        realm={realm}
                        isAuthorizedToEdit={false}
                        initialValue={metadata.experimentType.payloadValue}>
                    </ExperimentType>
                </ColumnLayout>
            </ExpandableSection>
        </div>
    );
};
