import React from 'react';
import { FormField, Spinner, FileUpload, FileUploadProps, NonCancelableCustomEvent, StatusIndicator } from '@amzn/awsui-components-react-v3';
import { ExperimentAttribute, ExperimentAttributeConfig, ExperimentAttributeProps } from '../ExperimentAttribute';
import { FileValidationRules } from '@amzn/limestone-experiment-portal-types';
import { getDuplicates, getUniqueValues } from '../../utils/array-utils';

export interface FileFieldConfig extends ExperimentAttributeConfig, Omit<FileUploadProps, 'errorText'> {
    value: any;
    payloadValue?: string[] | null;
}

/**
 * Field that will allow user to upload a file.
 * Reference: https://refresh.cloudscape.aws.dev/components/file-upload/
 */
export abstract class FileField<T extends  ExperimentAttributeProps> extends ExperimentAttribute<T> {
    protected displayConfig!: FileFieldConfig;
    protected summaryDisplay: any;

    parseValueFromEvent = (event: NonCancelableCustomEvent<FileUploadProps.ChangeDetail>): ReadonlyArray<File> => event.detail.value;

    getValidity = () => !(this.displayConfig.fileErrors && this.displayConfig.fileErrors.length > 0);

    getDisplayValue = () => this.displayConfig.label!;

    extractContentFromFile = async(file: File): Promise<any> => file;

    getPayloadValue = () => this.displayConfig.payloadValue;

    validateAdditionalRules = (_value: File, _fileContent: any, _validationRules: FileValidationRules): any => {
        return { isValid: true, errors: [] };
    }

    validateFile = (value: File, fileContent: any, validationRules: FileValidationRules) => {
        let isValid: boolean = true;
        const errors: string[] = [];

        if (!validationRules) {
            return { isValid, errors };
        }

        if (validationRules.required && !value) {
            isValid = false;
            errors.push('Field is required');
        }

        if (validationRules.allowedOptions && value && !validationRules.allowedOptions.includes(value.type)) {
            isValid = false;
            errors.push('This is not a valid file type, only supported types are: ' + validationRules.allowedOptions);
        }

        if (validationRules.maxSizeInBytes && value && value.size > validationRules.maxSizeInBytes) {
            isValid = false;
            errors.push('Max File Upload Size is ' + validationRules.maxSizeInBytes / 1000000 + ' MB');
        }

        if (validationRules.duplicateCheck && fileContent) {
            const duplicateValues = getDuplicates(fileContent);
            if (duplicateValues.size > 0) {
                isValid = false;
                errors.push(`${duplicateValues.size} duplicate item(s) present in the file`);
            }
        }

        const result = this.validateAdditionalRules(value, fileContent, validationRules);
        isValid = isValid && result.isValid;
        errors.push(...result.errors);

        return { isValid, errors: errors };
    }

    setValue = async(newValue: ReadonlyArray<File>) => {
        if (newValue && newValue.length) {
            const fileContent: string[] = await this.extractContentFromFile(newValue[0]);
            const uniqueValues = getUniqueValues(fileContent.map((value) => value.trim()).filter((value) => value.length > 0));
            this.validateAndSetFileValue(newValue, uniqueValues);
            this.summaryDisplay = newValue[0].name && this.state.validity ? (
                <div style={{ marginTop: '5px' }}>
                    <StatusIndicator type='success'>
                        {`File ${newValue[0].name} is now available`}
                        {fileContent && <div>{`Item Count: ${fileContent.length}`}</div>}
                    </StatusIndicator>
                </div>
            ) : null;
        } else {
            this.validateAndSetFileValue([]);
        }
    }

    validateAndSetFileValue = async(file: ReadonlyArray<File>, fileContent?: string[]) => {
        const { isValid, errors } = this.validateFile(file[0], fileContent, this.validationRules);
        this.displayConfig = {
            ...this.displayConfig,
            touched: true,
            fileErrors: errors,
            value: file,
            payloadValue: fileContent,
        };

        const fileName = file[0] ? file[0].name : '';
        await new Promise((resolve) => this.setState({
            displayValue: fileName,
            validity: isValid
        }, () => resolve(file)));
    }

    renderCreateMode = () => (
        <FormField
            data-testid={'create-wrapper'}
            label={this.displayConfig.label}
            constraintText={this.displayConfig.hintText}
            secondaryControl={undefined}
            errorText={(!this.state.validity && this.displayConfig.touched) ? this.displayConfig.errorText : undefined}>
            {this.getPolarisElement()}
        </FormField>
    );

    getPolarisElement = () => (
        !this.state.editInProgress ? <>
            <FileUpload {...this.displayConfig}/>
            {this.summaryDisplay}
        </> : <Spinner />
    )

    renderViewMode = () => <></>;
}
