import React, { FunctionComponent } from 'react';
import { Portal } from '@amzn/limestone-experiment-portal-types';
import { QuickLinksSection } from './QuickLinksSection';
import { AnnouncementSection } from './AnnouncementSection';
import { QuickStartSection } from './QuickStartSection';
import { RealmDisplaySection } from './RealmDisplaySection';
import { adminQuickLinkCardItems, basicQuickLinkCardItems, PageProps } from '@amzn/limestone-experiment-portal-types';


const HomePage: FunctionComponent<PageProps> = ({ realm, portal }) => {
    const cardItems = portal === Portal.ADMIN ? adminQuickLinkCardItems : basicQuickLinkCardItems;
    return (
        <>
            <QuickStartSection />
            <RealmDisplaySection realm={realm} />
            <QuickLinksSection cardItems={cardItems}/>
            <AnnouncementSection />
        </>
    );
};

export default HomePage;
