import { DisplayMode, MetadataAttribute } from '@amzn/limestone-experiment-portal-types';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { SelectField, SelectFieldConfig } from '../fields/SelectField';
import { AttributeLabels, ExperimentType } from '@amzn/limestone-experiment-portal-types';

export class ExperimentTypeField extends SelectField {
    protected displayConfig: SelectFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;

        this.validationRules = { required: true };

        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: AttributeLabels.EXPERIMENT_TYPE,
            editable: false,
            touched: false,
            placeholder: 'Select from the drop-down',
            filteringType: 'auto',
            hintText: 'Select the type of experiment which you want to conduct',
            errorText,
            onChange: (event) => this.onChangeEvent(event as CustomEvent, MetadataAttribute.EXPERIMENT_TYPE),
            selectedOption: null,
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }

        this.displayConfig.options = [
            { label: 'Static regional availability', value: ExperimentType.LISTER_EXPERIMENT.toString(), description: 'Default regional experiment which blocks set of offers in a static set of treatment regions' },
            { label: 'Use ReSES Science Engine', value: ExperimentType.RESULT_ANALYSIS_ONLY.toString(), disabled: false, description: 'Utilizing the ReSES Data Collection and Science engine for evaluating custom experiments.' },
            { label: 'Dynamic Regional Availability based on inventory availability', value: 'FULFILLER_EXPERIMENT', disabled: true, description: 'Regional Experiment which blocks offers in a set of treatment regions based on regional inventory availability' },
        ];

        this.forceUpdate();
    }

    getPayloadValue = (): string|undefined => this.displayConfig.selectedOption?.value;
}
