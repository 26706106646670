import { filterExperimentsByDate } from '../../interfaces/LimestoneExperiment';
import { LimestoneExperiment } from '@amzn/limestone-experiment-portal-types';
import { Container, Header } from '@amzn/awsui-components-react-v3';
import React, { Component } from 'react';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { DisplayTable } from '../../common/DisplayTable';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import * as NOTIFICATION_MESSAGES from '@amzn/limestone-experiment-portal-types';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import { getColumnDefinition, columnOptions, pageSizeOptions } from '../../constants/table/experiment-table/experiment-table-definition';
import { TableHeaders, PageProps } from '@amzn/limestone-experiment-portal-types';
import { mapExperimentsToExperimentsTableItems } from '../../utils/experiments-table-utils';
import { PermissionControlledView } from '../../permissions/PermissionControlledView';
import { ExperimentUserRoleType } from '@amzn/limestone-experiment-portal-types';
import { DateRangePickerProps } from '@amzn/awsui-components-react-v3/polaris/date-range-picker/interfaces';
import { DateRangeSelectorProps } from '../../common/DateRangeSelector';
import { SelectProps } from '@amzn/awsui-components-react-v3/polaris/select/interfaces';
import { filterItemsByAttribute } from '../../common/AttributeSelectFilter';
import {
    EXPERIMENT_FILTERING_PROPERTIES
} from '../../constants/table/experiment-table/experiment-table-filter-properties';

export interface MyExperimentsPageState {
    experiments: LimestoneExperiment[];
    tableLoading: boolean;
    /**
     * Selected experiment start date range to filter experiments.
     */
    selectedStartDateRangeValue: DateRangePickerProps.Value | null;
    /**
     * Selected experiment status to filter experiment.
     */
    selectedExperimentStatusOption: SelectProps.Option | null;
}

class MyExperimentsPage extends Component<PageProps, MyExperimentsPageState> {
    /**
     * Experiment Service handler instance which provides api to get the experiment data from the backend
     */
    public experimentServiceAPI: LemsApiHandler;

    constructor(props: PageProps) {
        super(props);
        this.state = {
            tableLoading: true,
            experiments: [],
            selectedStartDateRangeValue: null,
            selectedExperimentStatusOption: null,
        };

        this.experimentServiceAPI = new ApiHandler(props.realm);
    }

    componentDidMount = async() => await this.fetchExperiments();

    componentDidUpdate = async(prevProps: PageProps) => {
        if (prevProps.realm !== this.props.realm) {
            await this.fetchExperiments();
        }
    }

    fetchExperiments = async() => {
        await this.experimentServiceAPI.getExperimentsByUserAlias(this.props.userAttributes?.username!, [ExperimentUserRoleType.OWNER, ExperimentUserRoleType.OBSERVER])
            .then((response: LimestoneExperiment[]) => this.setState({ experiments: response }))
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentsByOwner.FAIL!))
            .finally(() => this.setState({ tableLoading: false }));
    }

    render() {
        const dateRangeSelectorProps: DateRangeSelectorProps = {
            onChangeEvent: ({ detail }) => this.setState({ selectedStartDateRangeValue: detail.value }),
            timeValue: this.state.selectedStartDateRangeValue,
            placeholder: 'Filter by experiment start date',
            testId: 'my-experiments-table-date-picker',
        };

        // Filter all the chosen attributes to get the experiments to display
        const myExperiments = this.state.experiments;
        const experimentsToDisplay = filterItemsByAttribute(
            mapExperimentsToExperimentsTableItems(
                filterExperimentsByDate(myExperiments, this.state.selectedStartDateRangeValue)),
            'currentStatusToDisplay', this.state.selectedExperimentStatusOption!);

        return (
            <PermissionControlledView
                userAccessLevels={this.props.userAccessLevels}
                pagePermissionsMap={this.props.permissionsMap}
            >
                <div style={{ padding: '20px' }}>
                    <Container>
                        <DisplayTable
                            data-testid='my-experiments-table'
                            title={
                                <Header variant="h2" counter={String(this.state.experiments.length)}>
                                    {TableHeaders.MY_EXPERIMENTS}
                                </Header>
                            }
                            items={experimentsToDisplay}
                            tableLoading={this.state.tableLoading}
                            columnDefinitions={getColumnDefinition(this.props.realm)}
                            columnOptions={columnOptions}
                            pageSizeOptions={pageSizeOptions}
                            preferencesEnabled={true}
                            initialSortingDescending={true}
                            initialSortingId={'lastUpdatedDate'}
                            dateRangeSelectorProps={dateRangeSelectorProps}
                            propertyFilterProperties={EXPERIMENT_FILTERING_PROPERTIES}
                            attributeSelectFilterProps={[
                                {
                                    items: mapExperimentsToExperimentsTableItems(myExperiments),
                                    attributeName: 'currentStatusToDisplay',
                                    attributeLabel: 'experiment status',
                                    selectedOption: this.state.selectedExperimentStatusOption!,
                                    onChangeEvent: ({ detail }) => this.setState({ selectedExperimentStatusOption: detail.selectedOption }),
                                }
                            ]}
                        />
                    </Container>
                </div>
            </PermissionControlledView>
        );
    }
}

export default MyExperimentsPage;
