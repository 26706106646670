import { SelectField, SelectFieldConfig } from '../fields/SelectField';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import * as LambdaModel from '@amzn/limestone-experiment-portal-types';
import { DisplayMode, MetadataAttribute } from '@amzn/limestone-experiment-portal-types';
import { AttributeLabels } from '@amzn/limestone-experiment-portal-types';

export class PrimaryBusinessGroup extends SelectField {
    protected displayConfig: SelectFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { displayMode } = props;

        this.displayConfig = {
            label: AttributeLabels.PRIMARY_BUSINESS_GROUP,
            editable: false,
            touched: false,
            placeholder: 'Select from the drop-down',
            filteringType: 'auto',
            onChange: (event) => this.onChangeEvent(event as CustomEvent, MetadataAttribute.PRIMARY_BUSINESS_GROUP),
            selectedOption: null,
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: true
        };
    }

    componentDidMount = async() => {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
    }

    getPayloadValue = (): LambdaModel.BusinessGroupDto => {
        return {
            businessGroup: this.state.displayValue,
            primary: true
        };
    };

    setValueFromPayload = async(businessGroupDto: LambdaModel.BusinessGroupDto) => {
        this.setValue({ value: businessGroupDto.businessGroup, label: businessGroupDto.businessGroup });
    }
}
