import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Header } from '@amzn/awsui-components-react-v3';

interface Announcement {
    title: string;
    content: string;
}

const useStyles = makeStyles(({
    header: {
        margin: '5px 15px',
        padding: '10px 10px 0 10px'
    },
    newsItem: {
        margin: '15px 15px',
        padding: '0 0 10px 10px'
    }
}));

export const AnnouncementSection: FunctionComponent = () => {
    const classes = useStyles();
    const initialAnnouncementState: Announcement[] = [];

    const [announcements,] = useState(initialAnnouncementState);

    return (
        <div>
            {announcements.length > 0 && <Container header={<Header variant='h1'>Announcements</Header>}>
                {announcements.map((announcement, index) => (
                    <div key={index} className={classes.newsItem}>
                        <Box variant={'strong'}>{announcement.title}</Box>
                        <Box>{announcement.content}</Box>
                    </div>
                ))}
            </Container>}
        </div>
    );
};
